* {
  font-family: 'Quicksand', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

.light2 {
  min-height: 100vh;
  height: max-content;
  overflow: hidden;
  background: radial-gradient(circle, rgba(0,0,0,0.3841911764705882) 0%, rgba(0,0,0,0.4962359943977591) 35%, rgba(0,0,0,0.5326505602240896) 100%), url('./assets/mainbanner.jpg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.btn2:disabled {
  cursor: no-drop;
  opacity: 0.5; /* Makes the button look disabled */
  background-color: #cccccc; /* Example of a different background color for the disabled state */
}

.btn1:disabled {
  cursor: no-drop;
  opacity: 0.3; /* Makes the button look disabled */
  background-color: #cccccc2d; /* Example of a different background color for the disabled state */
}


.liner {
  margin-bottom: 5%;
}

@keyframes disappear {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes pop {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

.logo:hover {
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #72603f5e;
  border-radius: 50px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #796642af;
}

.icons {
  display: flex;
}

@media screen and (max-width: 499px) {
  #mobTxt {
    font-size: 25px;
  }
  #noNFTs{
    font-size: 28px;
    color: #614d2c;
    font-weight: bolder;
  }
  .notification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    color: #b49560;
    font-weight: bold;
    width: 90%;
    text-align: center;
  }

  #refH {
    text-align: left;
    margin-bottom: 7px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .totalAmount {
    color: #8b7245;
    font-weight: bold;
    text-align: center;
  }

  .commissionAmount div {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  }

  .commissionAmount {
    display: flex;
    justify-content: space-evenly;
    margin-top: 3px;
    display: none;
  }

  .commissionAmountMobile {}

  .popup-content button:hover {
    background-color: #ceaa68;
  }

  .eachBoxHeaders {
    display: flex;
    justify-content: space-evenly;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px auto;
    border-radius: 50px;

  }

  th,
  td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #8b7245a6;
    font-size: 14px;
  }

  th {
    background-color: #be9b5a4d;
    color: white;
    border-radius: 10px;
    border-bottom: 1px solid #8b724500;
    width: 200px;
  }

  tr:nth-child(even) {
    background-color: #be9b5a00;
    border-radius: 50px;
    color: #e2bc76;

  }

  tr:nth-child(odd) {
    background-color: #8b724500;
    color: #e2bc76;
    border-radius: 50px;
  }

  #errorMessage {
    color: rgb(255, 171, 171);
    margin-top: 15px;
  }

  #loadingText {
    color: #d4bb8d;
    margin-top: 15px;
  }

  #success-message {
    color: #52b396;
    margin-top: 15px;
  }

  .popup {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .popup-content {
    width: 90%;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;

    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
  }

  .leaderboardMain {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
    margin-top: 7%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
    /* Add this line for horizontal scrolling */
  }


  h2 {
    margin-top: 0;
    font-size: 15px;
    text-align: center;
    color: #d4bb8d;
  }

  h3 {
    color: #af9668;
    text-shadow: 0 0 10px #9c865d;
    font-size: 22px;
  }

  .pDivHeaders {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    flex-flow: column nowrap;
  }

  .pDivHeadersSub {
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
    flex-flow: row wrap;
  }


  .refH-2 {
    margin-top: auto;
    margin-bottom: auto;

  }

  .h4 {
    font-size: 16px;
    color: #8b7245;
    transition: 0.2s ease-in-out;
  }

  .rank:hover {
    color: #be9b5a;
    cursor: pointer;
  }

  .user {
    width: 25px;
    margin-top: -8px;
    margin-right: 10px;
  }

  .user2 {
    width: 28px;
    margin-right: 7px;
  }


  .btnT {
    margin-top: -20px;
    margin-bottom: 10px;
  }

  .btnT button {
    background-color: #201c15;
    color: #af9668;
    border-radius: 100px;
    border: 1px;
    color: #ffffff;
    border-radius: 100px;
    border: 1px #8b7245 solid;
    background-color: #111111b2;
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(163, 133, 80, 0.637) 0px 7px 29px 0px;
    text-shadow: 0 0 45px #8df8d5;
    width: 90%;
    padding: 5px;
  }

  .btnT button:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 45px #8df8d5;
  }

  .btnT img {
    width: 22px;
    height: 22px;
    margin-right: 5px;
    margin-top: -5px;
    opacity: 0.6;
  }

  .accR {
    color: #8b7245;
    font-size: 14px;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 25px;
    z-index: 50;
    color: #a19c92;
    transition: 0.2s ease-in-out;
  }

  .close:hover {
    color: #d6d0c4;
  }

  .popup-content input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #8b7245;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #0a0900bb;
    color: #a58852;
  }

  .popup-content2 {
    width: 95%;
    height: 95vh;
    text-align: center;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

    background: rgba(255, 255, 255, 0.2);
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;

    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
  }

  /* NFT section styles */

  .nft-sectionMain {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 20px 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
  }

  .nft-section {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 5px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
  }

  .eachCollection {
    background-color: #44330ad0;
    border-radius: 20px;
    margin: 20px;
    padding: 5px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .eachCollection div {
    color: #b49560;
    text-align: center;
    font-size: 14px;
  }

  .nft-card {
    margin: 10px;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    width: 100px;
    border: 1px solid #8b724579;
    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(75, 53, 16, 0.277) 0%, rgba(43, 31, 12, 0.384) 61%, rgba(102, 81, 43, 0.851) 100%);
  }

  .nft {
    width: 90%;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .copy {
    width: 16px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 7px;
    cursor: pointer;
  }

  .nft-card p {
    font-size: 14px;
    margin-bottom: 10px;
    color: #fdf1db;
    text-shadow: 0 0 10px #ffffff3a;
    text-align: left;
  }

  #id {
    text-align: center;
    color: #ffe5b5;
    font-weight: bold;
    font-size: 14px;
  }

  .accIn {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .account {
    color: #cfcfcf;
    text-align: left;
  }

  .popup-content button {
    background-color: #a58852;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: 0.2s ease-in-out;
  }

  .socialIcon img {
    width: 28px;
    height: 28px;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }

  .light {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background: radial-gradient(circle, rgba(0,0,0,0.3841911764705882) 0%, rgba(0,0,0,0.4962359943977591) 35%, rgba(0,0,0,0.5326505602240896) 100%), url('./assets/bg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon {
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Poppins", sans-serif;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 70px;
  }

  .intro1 {
    color: white;
    text-align: center;
    font-size: 16px;
    text-shadow: 0 0 10px #8df8d5;
  }

  .intro2-mob {
    background: linear-gradient(to bottom, #8df8d5 22%, #634f2c 24%, #8df8d5 26%, #8df8d5 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Playfair Display', serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    font-size: 35px;
    width: 100%;
    text-align: center;
  }

  .intro2 {
    display: none;
  }

  .btnsMain {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 4%;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .eachBtns {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .eachBtns div {
    font-weight: 500;
    margin-bottom: 15px;
    color: rgb(167, 167, 167);
  }

  .btn1 {
    color: white;
    padding: 15px;
    border-radius: 100px;
    border: 1px #8b7245 solid;
    background-color: #111111b2;
    width: 230px;
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    font-size: 14px;
  }

  .btn1:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 10px #8df8d5;
  }

  .btn2 {
    color: white;
    padding: 15px;
    border-radius: 100px;
    background-color: #111111b2;
    width: 230px;
    background-color: #8b7245;
    border: none;
    background: rgb(99, 79, 44);
    background: linear-gradient(180deg, rgba(99, 79, 44, 1) 0%, rgb(156, 143, 115) 100%);
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    font-size: 14px;

  }

  .btn2:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 10px #8df8d5;
  }

  .logoPic {
    width: 80px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    width: 100%;
    background-color: #0a0900;
    color: #52b396;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
    text-shadow: 0 0 10px #fdd5968a;
    font-size: 14px;
  }

  .btn {
    display: inline-block;
    background: transparent;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    font-size: 10px;
    letter-spacing: 0.3em;
    color: rgba(223, 190, 106, 0.7);
    border-radius: 0;
    padding: 15px;
    transition: all 0.7s ease-out;
    background: linear-gradient(270deg, rgba(223, 190, 106, 0.8), rgba(146, 111, 52, 0.8), rgba(34, 34, 34, 0), rgba(34, 34, 34, 0));
    background-position: 1% 50%;
    background-size: 300% 300%;
    text-decoration: none;
    margin: 0.625rem;
    border: none;
    border: 1px solid rgba(223, 190, 106, 0.3);
    border-radius: 100px;
    box-shadow: 0 0 20px rgba(192, 169, 111, 0.37);
  }

  .btn:hover {
    color: #fff;
    border: 1px solid rgba(223, 190, 106, 0);
    background-position: 99% 50%;
  }
}


@media screen and (min-width: 500px) and (max-width: 767px) {
  #mobTxt {
    font-size: 25px;
  }
  #noNFTs{
    font-size: 28px;
    color: #614d2c;
    font-weight: bolder;
  }
  #refH {
    text-align: left;
    margin-bottom: 7px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .notification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    color: #b49560;
    font-weight: bold;
    width: 300px;
    text-align: center;
  }

  .totalAmount {
    color: #8b7245;
    font-weight: bold;
    text-align: center;
  }

  .commissionAmount div {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  }

  .commissionAmount {
    display: flex;
    justify-content: space-evenly;
    margin-top: 3px;
    display: none;
  }

  .popup-content button:hover {
    background-color: #ceaa68;
  }

  .eachBoxHeaders {
    display: flex;
    justify-content: space-evenly;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px auto;
    border-radius: 50px;

  }

  th,
  td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #8b7245a6;
    font-size: 14px;
  }

  th {
    background-color: #be9b5a4d;
    color: white;
    border-radius: 10px;
    border-bottom: 1px solid #8b724500;
    width: 200px;
  }

  tr:nth-child(even) {
    background-color: #be9b5a00;
    border-radius: 50px;
    color: #e2bc76;

  }

  tr:nth-child(odd) {
    background-color: #8b724500;
    color: #e2bc76;
    border-radius: 50px;
  }

  #errorMessage {
    color: rgb(255, 171, 171);
    margin-top: 15px;
  }

  #loadingText {
    color: #d4bb8d;
    margin-top: 15px;
  }

  #success-message {
    color: #52b396;
    margin-top: 15px;
  }

  .popup {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .popup-content {
    width: 90%;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;

    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
  }

  .leaderboardMain {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
    margin-top: 7%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
    /* Add this line for horizontal scrolling */
  }


  h2 {
    margin-top: 0;
    font-size: 15px;
    text-align: center;
    color: #d4bb8d;
  }

  h3 {
    color: #af9668;
    text-shadow: 0 0 10px #9c865d;
    font-size: 22px;
  }

  .pDivHeaders {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    flex-flow: column nowrap;
  }

  .pDivHeadersSub {
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
    flex-flow: row wrap;
  }


  .refH-2 {
    margin-top: auto;
    margin-bottom: auto;

  }

  .h4 {
    font-size: 16px;
    color: #8b7245;
    transition: 0.2s ease-in-out;
  }

  .rank:hover {
    color: #be9b5a;
    cursor: pointer;
  }

  .user {
    width: 25px;
    margin-top: -8px;
    margin-right: 10px;
  }

  .user2 {
    width: 28px;
    margin-right: 7px;
  }


  .btnT {
    margin-top: -20px;
    margin-bottom: 10px;
  }

  .btnT button {
    background-color: #201c15;
    color: #af9668;
    border-radius: 100px;
    border: 1px;
    color: #ffffff;
    border-radius: 100px;
    border: 1px #8b7245 solid;
    background-color: #111111b2;
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(163, 133, 80, 0.637) 0px 7px 29px 0px;
    text-shadow: 0 0 45px #8df8d5;
    width: 90%;
    padding: 5px;
  }

  .btnT button:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 45px #8df8d5;
  }

  .btnT img {
    width: 22px;
    height: 22px;
    margin-right: 5px;
    margin-top: -5px;
    opacity: 0.6;
  }

  .accR {
    color: #8b7245;
    font-size: 14px;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 25px;
    z-index: 50;
    color: #a19c92;
    transition: 0.2s ease-in-out;
  }

  .close:hover {
    color: #d6d0c4;
  }

  .popup-content input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #8b7245;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #0a0900bb;
    color: #a58852;
  }

  .popup-content2 {
    width: 95%;
    height: 95vh;
    text-align: center;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

    background: rgba(255, 255, 255, 0.2);
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;

    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
  }

  /* NFT section styles */

  .nft-sectionMain {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 20px 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
  }

  .nft-section {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 20px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
  }

  .eachCollection {
    background-color: #35290ed0;
    border-radius: 20px;
    margin: 20px;
    padding: 10px;
  }

  .eachCollection div {
    color: #b49560;
    text-align: center;
    font-size: 14px;
  }

  .nft-card {
    padding: 10px;
    margin: 10px;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    width: 150px;
    border: 1px solid #8b724579;
    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(75, 53, 16, 0.277) 0%, rgba(43, 31, 12, 0.384) 61%, rgba(102, 81, 43, 0.851) 100%);
  }

  .nft {
    width: 90%;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .copy {
    width: 16px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 7px;
    cursor: pointer;
  }

  .nft-card p {
    font-size: 14px;
    margin-bottom: 10px;
    color: #fdf1db;
    text-shadow: 0 0 10px #ffffff3a;
    text-align: left;
  }

  #id {
    text-align: center;
    color: #ffe5b5;
    font-weight: bold;
  }

  .accIn {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .account {
    color: #cfcfcf;
    text-align: left;
  }

  .popup-content button {
    background-color: #a58852;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: 0.2s ease-in-out;
  }

  .intro2-mob {
    display: none;
  }

  .socialIcon img {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }

  .light {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background: radial-gradient(circle, rgba(0,0,0,0.3841911764705882) 0%, rgba(0,0,0,0.4962359943977591) 35%, rgba(0,0,0,0.5326505602240896) 100%), url('./assets/bg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon {
    padding-right: 15px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Poppins", sans-serif;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 70px;
  }

  .intro1 {
    color: white;
    text-align: center;
    font-size: 16px;
    text-shadow: 0 0 10px #8df8d5;
  }

  .intro2 {
    background: linear-gradient(to bottom, #8df8d5 22%, #634f2c 24%, #8df8d5 26%, #8df8d5 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Playfair Display', serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    font-size: 30px;
    width: 100%;
    text-align: center;
  }

  .btnsMain {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 4%;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .eachBtns {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .eachBtns div {
    font-weight: 500;
    margin-bottom: 15px;
    color: rgb(167, 167, 167);
  }

  .btn1 {
    color: white;
    padding: 15px;
    border-radius: 100px;
    border: 1px #8b7245 solid;
    background-color: #111111b2;
    width: 230px;
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    font-size: 15px;
  }

  .btn1:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 10px #8df8d5;
  }

  .btn2 {
    color: white;
    padding: 15px;
    border-radius: 100px;
    background-color: #111111b2;
    width: 230px;
    background-color: #8b7245;
    border: none;
    background: rgb(99, 79, 44);
    background: linear-gradient(180deg, rgba(99, 79, 44, 1) 0%, rgb(156, 143, 115) 100%);
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    font-size: 15px;

  }

  .btn2:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 10px #8df8d5;
  }

  .logoPic {
    width: 80px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    width: 100%;
    background-color: #0a0900;
    color: #52b396;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
    text-shadow: 0 0 10px #fdd5968a;
    font-size: 16px;
  }

  .btn {
    display: inline-block;
    background: transparent;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    font-size: 10px;
    letter-spacing: 0.3em;
    color: rgba(223, 190, 106, 0.7);
    border-radius: 0;
    padding: 14px 25px 16px;
    transition: all 0.7s ease-out;
    background: linear-gradient(270deg, rgba(223, 190, 106, 0.8), rgba(146, 111, 52, 0.8), rgba(34, 34, 34, 0), rgba(34, 34, 34, 0));
    background-position: 1% 50%;
    background-size: 300% 300%;
    text-decoration: none;
    margin: 0.625rem;
    border: none;
    border: 1px solid rgba(223, 190, 106, 0.3);
    border-radius: 100px;
    box-shadow: 0 0 20px rgba(192, 169, 111, 0.37);
  }

  .btn:hover {
    color: #fff;
    border: 1px solid rgba(223, 190, 106, 0);
    background-position: 99% 50%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #mobTxt {
    font-size: 25px;
  }
  #noNFTs{
    font-size: 35px;
    color: #614d2c;
    font-weight: bolder;
  }
  .notification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    color: #b49560;
    font-weight: bold;
    width: 300px;
    text-align: center;
  }

  #refH {
    text-align: left;
    margin-bottom: 7px;
  }

  .totalAmount {
    color: #8b7245;
    font-weight: bold;
    text-align: center;
  }

  .commissionAmount div {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
  }

  .commissionAmountMobile {
    display: none;
  }

  .commissionAmount {
    display: flex;
    justify-content: space-evenly;
  }

  .popup-content button:hover {
    background-color: #ceaa68;
  }

  .eachBoxHeaders {
    display: flex;
    justify-content: space-evenly;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px auto;
    border-radius: 50px;

  }

  th,
  td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #8b7245a6;

  }

  th {
    background-color: #be9b5a4d;
    color: white;
    border-radius: 10px;
    border-bottom: 1px solid #8b724500;
    width: 200px;
  }

  tr:nth-child(even) {
    background-color: #be9b5a00;
    border-radius: 50px;
    color: #e2bc76;

  }

  tr:nth-child(odd) {
    background-color: #8b724500;
    color: #e2bc76;
    border-radius: 50px;
  }

  #errorMessage {
    color: rgb(255, 171, 171);
    margin-top: 15px;
  }

  #loadingText {
    color: #d4bb8d;
    margin-top: 15px;
  }

  #success-message {
    color: #52b396;
    margin-top: 15px;
  }

  .popup {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .popup-content {
    width: 60%;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;

    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
  }

  .leaderboardMain {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
    margin-top: 7%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    margin-top: 0;
    font-size: 17px;
    text-align: center;
    color: #d4bb8d;
  }

  h3 {
    color: #af9668;
    text-shadow: 0 0 10px #9c865d;
    font-size: 22px;
  }

  .pDivHeaders {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    flex-flow: column nowrap;
  }

  .pDivHeadersSub {
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
    flex-flow: row nowrap;
  }


  .refH-2 {
    margin-top: auto;
    margin-bottom: auto;

  }

  .h4 {
    font-size: 16px;
    color: #8b7245;
    transition: 0.2s ease-in-out;
  }

  .rank:hover {
    color: #be9b5a;
    cursor: pointer;
  }

  .user {
    width: 25px;
    margin-top: -8px;
    margin-right: 10px;
  }

  .user2 {
    width: 28px;
    margin-right: 7px;
  }


  .btnT {
    margin-top: -20px;
    margin-bottom: 10px;
  }

  .btnT button {
    background-color: #201c15;
    color: #af9668;
    border-radius: 100px;
    border: 1px;
    color: #ffffff;
    border-radius: 100px;
    border: 1px #8b7245 solid;
    background-color: #111111b2;
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(163, 133, 80, 0.637) 0px 7px 29px 0px;
    text-shadow: 0 0 45px #8df8d5;
    width: 90%;
    padding: 5px;
  }

  .btnT button:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 45px #8df8d5;
  }

  .btnT img {
    width: 22px;
    height: 22px;
    margin-right: 5px;
    margin-top: -5px;
    opacity: 0.6;
  }

  .accR {
    color: #8b7245;
    font-size: 14px;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 25px;
    z-index: 50;
    color: #a19c92;
    transition: 0.2s ease-in-out;
  }

  .close:hover {
    color: #d6d0c4;
  }

  .popup-content input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #8b7245;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #0a0900bb;
    color: #a58852;
  }

  .popup-content2 {
    width: 95%;
    height: 95vh;
    text-align: center;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

    background: rgba(255, 255, 255, 0.2);
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;

    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
  }

  /* NFT section styles */

  .nft-sectionMain {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 20px 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
  }

  .nft-section {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 20px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
  }

  .eachCollection {
    background-color: #35290ed0;
    border-radius: 20px;
    margin: 20px;
    padding: 10px;
  }

  .eachCollection div {
    color: #b49560;
    text-align: center;
  }

  .nft-card {
    padding: 10px;
    margin: 10px;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    width: 150px;
    border: 1px solid #8b724579;
    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(75, 53, 16, 0.277) 0%, rgba(43, 31, 12, 0.384) 61%, rgba(102, 81, 43, 0.851) 100%);
  }

  .nft {
    width: 90%;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .copy {
    width: 16px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 7px;
    cursor: pointer;
  }

  .nft-card p {
    font-size: 14px;
    margin-bottom: 10px;
    color: #fdf1db;
    text-shadow: 0 0 10px #ffffff3a;
    text-align: left;
  }

  #id {
    text-align: center;
    color: #ffe5b5;
    font-weight: bold;
  }

  .accIn {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .account {
    color: #cfcfcf;
    text-align: left;
  }

  .popup-content button {
    background-color: #a58852;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: 0.2s ease-in-out;
  }

  .intro2-mob {
    display: none;
  }

  .socialIcon img {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }

  .light {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background: radial-gradient(circle, rgba(0,0,0,0.3841911764705882) 0%, rgba(0,0,0,0.4962359943977591) 35%, rgba(0,0,0,0.5326505602240896) 100%), url('./assets/bg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon {
    padding-right: 15px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Poppins", sans-serif;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 70px;
  }

  .intro1 {
    color: white;
    text-align: center;
    font-size: 18px;
    text-shadow: 0 0 10px #8df8d5;
  }

  .intro2 {
    background: linear-gradient(to bottom, #8df8d5 22%, #634f2c 24%, #8df8d5 26%, #8df8d5 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Playfair Display', serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    font-size: 40px;
    width: 100%;
    text-align: center;
  }

  .btnsMain {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 4%;
    justify-content: space-evenly;
    width: 600px;
  }

  .eachBtns {
    text-align: center;
  }

  .eachBtns div {
    font-weight: 500;
    margin-bottom: 15px;
    color: rgb(167, 167, 167);
  }

  .btn1 {
    color: white;
    padding: 15px;
    border-radius: 100px;
    border: 1px #8b7245 solid;
    background-color: #111111b2;
    width: 230px;
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
  }

  .btn1:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 10px #8df8d5;
  }

  .btn2 {
    color: white;
    padding: 15px;
    border-radius: 100px;
    background-color: #111111b2;
    width: 230px;
    background-color: #8b7245;
    border: none;
    background: rgb(99, 79, 44);
    background: linear-gradient(180deg, rgba(99, 79, 44, 1) 0%, rgb(156, 143, 115) 100%);
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;

  }

  .btn2:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 10px #8df8d5;
  }

  .logoPic {
    width: 100px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    width: 100%;
    background-color: #0a0900;
    color: #52b396;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
    text-shadow: 0 0 10px #fdd5968a;
  }

  .btn {
    display: inline-block;
    background: transparent;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    font-size: 11px;
    letter-spacing: 0.3em;
    color: rgba(223, 190, 106, 0.7);
    border-radius: 0;
    padding: 15px 40px 18px;
    transition: all 0.7s ease-out;
    background: linear-gradient(270deg, rgba(223, 190, 106, 0.8), rgba(146, 111, 52, 0.8), rgba(34, 34, 34, 0), rgba(34, 34, 34, 0));
    background-position: 1% 50%;
    background-size: 300% 300%;
    text-decoration: none;
    margin: 0.625rem;
    border: none;
    border: 1px solid rgba(223, 190, 106, 0.3);
    border-radius: 100px;
    box-shadow: 0 0 20px rgba(192, 169, 111, 0.37);
  }

  .btn:hover {
    color: #fff;
    border: 1px solid rgba(223, 190, 106, 0);
    background-position: 99% 50%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  #noNFTs{
    font-size: 35px;
    color: #614d2c;
    font-weight: bolder;
  }
  .notification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    color: #b49560;
    font-weight: bold;
    width: 300px;
    text-align: center;
  }

  .totalAmount {
    color: #8b7245;
    font-weight: bold;
    text-align: center;
  }

  .commissionAmount div {
    padding-left: 10px;
    padding-right: 10px;
  }

  .commissionAmountMobile {
    display: none;
  }

  .commissionAmount {
    display: flex;
    justify-content: space-evenly;
  }

  .popup-content button:hover {
    background-color: #ceaa68;
  }

  .eachBoxHeaders {
    display: flex;
    justify-content: space-evenly;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px auto;
    border-radius: 50px;

  }

  th,
  td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #8b7245a6;

  }

  th {
    background-color: #be9b5a4d;
    color: white;
    border-radius: 10px;
    border-bottom: 1px solid #8b724500;
    width: 200px;
  }

  tr:nth-child(even) {
    background-color: #be9b5a00;
    border-radius: 50px;
    color: #e2bc76;

  }

  tr:nth-child(odd) {
    background-color: #8b724500;
    color: #e2bc76;
    border-radius: 50px;
  }

  #errorMessage {
    color: rgb(255, 171, 171);
    margin-top: 15px;
  }

  #loadingText {
    color: #d4bb8d;
    margin-top: 15px;
  }

  #success-message {
    color: #52b396;
    margin-top: 15px;
  }

  .popup {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .popup-content {
    width: 30%;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;

    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
  }

  .leaderboardMain {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
    margin-top: 7%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    margin-top: 0;
    font-size: 17px;
    text-align: center;
    color: #d4bb8d;
  }

  h3 {
    color: #af9668;
    text-shadow: 0 0 10px #9c865d;
    font-size: 22px;
  }

  .pDivHeaders {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .pDivHeadersSub {
    display: flex;
    justify-content: space-evenly;
    margin-top: 7px;
  }

  .refH {
    margin-right: 25px;

  }

  .refH-2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .h4 {
    font-size: 16px;
    color: #8b7245;
    transition: 0.2s ease-in-out;
  }

  .rank:hover {
    color: #be9b5a;
    cursor: pointer;
  }

  .user {
    width: 25px;
    margin-top: -8px;
    margin-right: 10px;
  }

  .user2 {
    width: 28px;
    margin-top: -8px;
    margin-right: 7px;
  }


  .btnT {
    margin-top: -20px;
    margin-bottom: 10px;
  }

  .btnT button {
    background-color: #201c15;
    color: #af9668;
    border-radius: 100px;
    border: 1px;
    color: #ffffff;
    border-radius: 100px;
    border: 1px #8b7245 solid;
    background-color: #111111b2;
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(163, 133, 80, 0.637) 0px 7px 29px 0px;
    text-shadow: 0 0 45px #8df8d5;
    width: 90%;
    padding: 5px;
  }

  .btnT button:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 45px #8df8d5;
  }

  .btnT img {
    width: 22px;
    height: 22px;
    margin-right: 5px;
    margin-top: -5px;
    opacity: 0.6;
  }

  .accR {
    color: #8b7245;
    font-size: 14px;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 25px;
    z-index: 50;
    color: #a19c92;
    transition: 0.2s ease-in-out;
  }

  .close:hover {
    color: #d6d0c4;
  }

  .popup-content input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #8b7245;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #0a0900bb;
    color: #a58852;
  }

  .popup-content2 {
    width: 95%;
    height: 95vh;
    text-align: center;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

    background: rgba(255, 255, 255, 0.2);
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;

    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
  }

  /* NFT section styles */

  .nft-sectionMain {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 20px 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
  }

  .nft-section {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 20px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
  }

  .eachCollection {
    background-color: #35290ed0;
    border-radius: 20px;
    margin: 20px;
    padding: 10px;
  }

  .eachCollection div {
    color: #b49560;
    text-align: center;
  }

  .nft-card {
    padding: 10px;
    margin: 10px;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    width: 20%;
    border: 1px solid #8b724579;
    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(75, 53, 16, 0.277) 0%, rgba(43, 31, 12, 0.384) 61%, rgba(102, 81, 43, 0.851) 100%);
  }

  .nft {
    width: 90%;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .copy {
    width: 16px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 7px;
    cursor: pointer;
  }

  .nft-card p {
    font-size: 14px;
    margin-bottom: 10px;
    color: #fdf1db;
    text-shadow: 0 0 10px #ffffff3a;
    text-align: left;
  }

  #id {
    text-align: center;
    color: #ffe5b5;
    font-weight: bold;
  }

  .accIn {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .account {
    color: #cfcfcf;
    text-align: left;
  }

  .popup-content button {
    background-color: #a58852;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: 0.2s ease-in-out;
  }

  .intro2-mob {
    display: none;
  }

  .socialIcon img {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }

  .light {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background: radial-gradient(circle, rgba(0,0,0,0.3841911764705882) 0%, rgba(0,0,0,0.4962359943977591) 35%, rgba(0,0,0,0.5326505602240896) 100%), url('./assets/bg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon {
    padding-right: 15px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Poppins", sans-serif;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 70px;
  }

  .intro1 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
    text-shadow: 0 0 10px #8df8d5;
  }

  #mobTxt {
    font-size: 35px;
  }

  .intro2 {
    background: linear-gradient(to bottom, #8df8d5 22%, #634f2c 24%, #8df8d5 26%, #8df8d5 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Playfair Display', serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    font-size: 40px;
    width: 100%;
    text-align: center;
  }

  .btnsMain {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 4%;
    justify-content: space-evenly;
    width: 600px;
  }

  .eachBtns {
    text-align: center;
  }

  .eachBtns div {
    font-weight: 500;
    margin-bottom: 15px;
    color: rgb(167, 167, 167);
  }

  .btn1 {
    color: white;
    padding: 15px;
    border-radius: 100px;
    border: 1px #8b7245 solid;
    background-color: #111111b2;
    width: 230px;
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
  }

  .btn1:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 10px #8df8d5;
  }

  .btn2 {
    color: white;
    padding: 15px;
    border-radius: 100px;
    background-color: #111111b2;
    width: 230px;
    background-color: #8b7245;
    border: none;
    background: rgb(99, 79, 44);
    background: linear-gradient(180deg, rgba(99, 79, 44, 1) 0%, rgb(156, 143, 115) 100%);
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;

  }

  .btn2:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 10px #8df8d5;
  }

  .logoPic {
    width: 100px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    width: 100%;
    background-color: #0a0900;
    color: #52b396;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
    text-shadow: 0 0 10px #fdd5968a;
  }

  .btn {
    display: inline-block;
    background: transparent;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    font-size: 11px;
    letter-spacing: 0.3em;
    color: rgba(223, 190, 106, 0.7);
    border-radius: 0;
    padding: 15px 40px 18px;
    transition: all 0.7s ease-out;
    background: linear-gradient(270deg, rgba(223, 190, 106, 0.8), rgba(146, 111, 52, 0.8), rgba(34, 34, 34, 0), rgba(34, 34, 34, 0));
    background-position: 1% 50%;
    background-size: 300% 300%;
    text-decoration: none;
    margin: 0.625rem;
    border: none;
    border: 1px solid rgba(223, 190, 106, 0.3);
    border-radius: 100px;
    box-shadow: 0 0 20px rgba(192, 169, 111, 0.37);
  }

  .btn:hover {
    color: #fff;
    border: 1px solid rgba(223, 190, 106, 0);
    background-position: 99% 50%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  #noNFTs{
    font-size: 35px;
    color: #614d2c;
    font-weight: bolder;
  }

  .notification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 7px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    color: #b49560;
    font-weight: bold;
    width: 300px;
    text-align: center;
  }

  .totalAmount {
    color: #8b7245;
    font-weight: bold;
    text-align: center;
  }

  .commissionAmount div {
    padding-left: 10px;
    padding-right: 10px;
  }

  .commissionAmount {
    display: flex;
    justify-content: space-evenly;
  }

  .commissionAmountMobile {
    display: none;
  }

  .popup-content button:hover {
    background-color: #ceaa68;
  }

  .eachBoxHeaders {
    display: flex;
    justify-content: space-evenly;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px auto;
    border-radius: 50px;

  }

  th,
  td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #8b7245a6;

  }

  th {
    background-color: #be9b5a4d;
    color: white;
    border-radius: 10px;
    border-bottom: 1px solid #8b724500;
    width: 200px;
  }

  tr:nth-child(even) {
    background-color: #be9b5a00;
    border-radius: 50px;
    color: #e2bc76;

  }

  tr:nth-child(odd) {
    background-color: #8b724500;
    color: #e2bc76;
    border-radius: 50px;
  }

  #errorMessage {
    color: rgb(255, 171, 171);
    margin-top: 15px;
  }

  #loadingText {
    color: #d4bb8d;
    margin-top: 15px;
  }

  #success-message {
    color: #52b396;
    margin-top: 15px;
  }

  .popup {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .popup-content {
    width: 30%;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;

    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
  }

  .leaderboardMain {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
    margin-top: 7%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    margin-top: 0;
    font-size: 18px;
    text-align: center;
    color: #d4bb8d;
  }

  h3 {
    color: #af9668;
    text-shadow: 0 0 10px #9c865d;
  }

  .pDivHeaders {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .pDivHeadersSub {
    display: flex;
    justify-content: space-evenly;
  }

  .refH-2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .h4 {
    font-size: 18px;
    color: #8b7245;
    transition: 0.2s ease-in-out;
  }

  .rank:hover {
    color: #be9b5a;
    cursor: pointer;
  }

  .user {
    width: 32px;
    margin-top: -8px;
    margin-right: 10px;
  }

  .user2 {
    width: 28px;
    margin-top: -8px;
    margin-right: 7px;
  }


  .btnT {
    margin-top: -20px;
    margin-bottom: 10px;
  }

  .btnT button {
    background-color: #201c15;
    color: #af9668;
    border-radius: 100px;
    border: 1px;
    color: #ffffff;
    border-radius: 100px;
    border: 1px #8b7245 solid;
    background-color: #111111b2;
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(163, 133, 80, 0.637) 0px 7px 29px 0px;
    text-shadow: 0 0 45px #8df8d5;
    width: 90%;
    padding: 5px;
  }

  .btnT button:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 45px #8df8d5;
  }

  .btnT img {
    width: 22px;
    height: 22px;
    margin-right: 5px;
    margin-top: -5px;
    opacity: 0.6;
  }

  .accR {
    color: #8b7245;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 25px;
    z-index: 50;
    color: #a19c92;
    transition: 0.2s ease-in-out;
  }

  .close:hover {
    color: #d6d0c4;
  }

  .popup-content input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #8b7245;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #0a0900bb;
    color: #a58852;
  }

  .popup-content2 {
    width: 95%;
    height: 95vh;
    text-align: center;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

    background: rgba(255, 255, 255, 0.2);
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;

    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
  }

  /* NFT section styles */

  .nft-sectionMain {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 20px 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
  }

  .nft-section {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 20px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
  }

  .eachCollection {
    background-color: #35290ed0;
    border-radius: 20px;
    margin: 20px;
    padding: 10px;
  }

  .eachCollection div {
    color: #b49560;
    text-align: center;
  }

  .nft-card {
    padding: 10px;
    margin: 10px;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    width: 20%;
    border: 1px solid #8b724579;
    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(75, 53, 16, 0.277) 0%, rgba(43, 31, 12, 0.384) 61%, rgba(102, 81, 43, 0.851) 100%);
  }

  .nft {
    width: 90%;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .copy {
    width: 16px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 7px;
    cursor: pointer;
  }

  .nft-card p {
    font-size: 14px;
    margin-bottom: 10px;
    color: #fdf1db;
    text-shadow: 0 0 10px #ffffff3a;
    text-align: left;
  }

  #id {
    text-align: center;
    color: #ffe5b5;
    font-weight: bold;
  }

  .accIn {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .account {
    color: #cfcfcf;
    text-align: left;
  }

  .popup-content button {
    background-color: #a58852;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: 0.2s ease-in-out;
  }

  .intro2-mob {
    display: none;
  }

  .socialIcon img {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }

  .light {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background: radial-gradient(circle, rgba(0,0,0,0.3841911764705882) 0%, rgba(0,0,0,0.4962359943977591) 35%, rgba(0,0,0,0.5326505602240896) 100%), url('./assets/bg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon {
    padding-right: 15px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Poppins", sans-serif;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 4.5%;
  }

  .intro1 {
    color: white;
    text-align: center;
    font-size: 18px;
    text-shadow: 0 0 10px #8df8d5;
  }

  .intro2 {
    background: linear-gradient(to bottom, #8df8d5 22%, #634f2c 24%, #8df8d5 26%, #8df8d5 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Playfair Display', serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    font-size: 40px;
    width: 100%;
    text-align: center;
  }

  .btnsMain {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 4%;
    justify-content: space-evenly;
    width: 600px;
  }

  .eachBtns {
    text-align: center;
  }

  .eachBtns div {
    font-weight: 500;
    margin-bottom: 15px;
    color: rgb(167, 167, 167);
  }

  .btn1 {
    color: white;
    padding: 15px;
    border-radius: 100px;
    border: 1px #8b7245 solid;
    background-color: #111111b2;
    width: 230px;
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
  }

  .btn1:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 10px #8df8d5;
  }

  .btn2 {
    color: white;
    padding: 15px;
    border-radius: 100px;
    background-color: #111111b2;
    width: 230px;
    background-color: #8b7245;
    border: none;
    background: rgb(99, 79, 44);
    background: linear-gradient(180deg, rgba(99, 79, 44, 1) 0%, rgb(156, 143, 115) 100%);
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;

  }

  .btn2:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 10px #8df8d5;
  }

  .logoPic {
    width: 100px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    width: 100%;
    background-color: #0a0900;
    color: #52b396;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
    text-shadow: 0 0 10px #fdd5968a;
  }

  .btn {
    display: inline-block;
    background: transparent;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    font-size: 11px;
    letter-spacing: 0.3em;
    color: rgba(223, 190, 106, 0.7);
    border-radius: 0;
    padding: 15px 40px 18px;
    transition: all 0.7s ease-out;
    background: linear-gradient(270deg, rgba(223, 190, 106, 0.8), rgba(146, 111, 52, 0.8), rgba(34, 34, 34, 0), rgba(34, 34, 34, 0));
    background-position: 1% 50%;
    background-size: 300% 300%;
    text-decoration: none;
    margin: 0.625rem;
    border: none;
    border: 1px solid rgba(223, 190, 106, 0.3);
    border-radius: 100px;
    box-shadow: 0 0 20px rgba(192, 169, 111, 0.37);
  }

  .btn:hover {
    color: #fff;
    border: 1px solid rgba(223, 190, 106, 0);
    background-position: 99% 50%;
  }
}

@media screen and (min-width: 1920px) {

  #noNFTs{
    font-size: 55px;
    color: #614d2c;
    font-weight: bolder;
  }
  .totalAmount {
    color: #8b7245;
    font-weight: bold;
    text-align: center;
    font-size: 22px;
  }

  .notification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    color: #b49560;
    font-weight: bold;
    width: 400px;
    text-align: center;
    font-size: 22px;
  }

  .commissionAmount div {
    padding-left: 10px;
    padding-right: 10px;
  }

  .commissionAmount {
    display: flex;
    justify-content: space-evenly;
  }

  .commissionAmountMobile {
    display: none;
  }

  .popup-content button:hover {
    background-color: #ceaa68;
  }

  .eachBoxHeaders {
    display: flex;
    justify-content: space-evenly;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px auto;
    border-radius: 60px;
  }

  th,
  td {
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid #8b7245a6;
    font-size: 22px;
  }

  th {
    background-color: #be9b5a4d;
    color: white;
    border-radius: 15px;
    border-bottom: 1px solid #8b724500;
    width: 200px;
    font-size: 22px;
  }

  tr:nth-child(even) {
    background-color: #be9b5a00;
    border-radius: 60px;
    color: #e2bc76;

  }

  tr:nth-child(odd) {
    background-color: #8b724500;
    color: #e2bc76;
    border-radius: 60px;
  }

  #errorMessage {
    color: rgb(255, 171, 171);
    margin-top: 20px;
    font-size: 22px;
  }

  #loadingText {
    color: #d4bb8d;
    margin-top: 20px;
    font-size: 22px;
  }

  #success-message {
    color: #52b396;
    margin-top: 20px;
    font-size: 22px;
  }

  .popup {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .popup-content {
    width: 30%;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 22px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;

    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
  }

  .leaderboardMain {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
    margin-top: 7%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    margin-top: 0;
    font-size: 24px;
    text-align: center;
    color: #d4bb8d;
  }

  h3 {
    color: #af9668;
    text-shadow: 0 0 10px #9c865d;
    font-size: 38px;
  }

  .pDivHeaders {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .pDivHeadersSub {
    display: flex;
    justify-content: space-evenly;
  }

  .refH {
    margin-right: 30px
  }

  .refH-2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .h4 {
    font-size: 24px;
    color: #8b7245;
    transition: 0.2s ease-in-out;
  }

  .rank:hover {
    color: #be9b5a;
    cursor: pointer;
  }

  .user {
    width: 45px;
    margin-top: -8px;
    margin-right: 15px;
  }

  .user2 {
    width: 40px;
    margin-top: -8px;
    margin-right: 12px;
  }


  .btnT {
    margin-top: -20px;
    margin-bottom: 10px;
  }

  .btnT button {
    background-color: #201c15;
    color: #af9668;
    border-radius: 100px;
    border: 1px;
    color: #ffffff;
    border-radius: 100px;
    border: 1px #8b7245 solid;
    background-color: #111111b2;
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(163, 133, 80, 0.637) 0px 7px 29px 0px;
    text-shadow: 0 0 45px #8df8d5;
    width: 90%;
    padding: 5px;
  }

  .btnT button:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 45px #8df8d5;
  }

  .btnT img {
    width: 22px;
    height: 22px;
    margin-right: 5px;
    margin-top: -5px;
    opacity: 0.6;
  }

  .accR {
    color: #8b7245;
    font-size: 22px;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 25px;
    z-index: 50;
    color: #a19c92;
    transition: 0.2s ease-in-out;
  }

  .close:hover {
    color: #d6d0c4;
  }

  .popup-content input[type="text"] {
    width: 100%;
    padding: 12px;
    border: 1px solid #8b7245;
    border-radius: 7px;
    box-sizing: border-box;
    background-color: #0a0900bb;
    color: #a58852;
    font-size: 22px;
  }

  .popup-content2 {
    width: 95%;
    height: 95vh;
    text-align: center;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

    background: rgba(255, 255, 255, 0.2);
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;

    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
  }

  /* NFT section styles */

  .nft-sectionMain {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 20px 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
  }

  .nft-section {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 20px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
  }

  .eachCollection {
    background-color: #35290ed0;
    border-radius: 30px;
    margin: 30px;
    padding: 20px;
  }

  .eachCollection div {
    color: #b49560;
    text-align: center;
    font-size: 22px;
  }

  .nft-card {
    padding: 10px;
    margin: 10px;
    border-radius: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    width: 20%;
    border: 1px solid #8b724579;
    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(75, 53, 16, 0.277) 0%, rgba(43, 31, 12, 0.384) 61%, rgba(102, 81, 43, 0.851) 100%);
  }

  .nft {
    width: 90%;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .copy {
    width: 22px;
    height: 22px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 7px;
    cursor: pointer;
  }

  .nft-card p {
    font-size: 14px;
    margin-bottom: 10px;
    color: #fdf1db;
    text-shadow: 0 0 10px #ffffff3a;
    text-align: left;
  }

  #id {
    text-align: center;
    color: #ffe5b5;
    font-weight: bold;
    font-size: 22px;
  }

  .accIn {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .account {
    color: #cfcfcf;
    text-align: left;
    font-size: 22px;
  }

  .popup-content button {
    background-color: #a58852;
    color: white;
    padding: 15px 25px;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    font-size: 22px;
    transition: 0.2s ease-in-out;
  }

  .intro2-mob {
    display: none;
  }

  .intro2-mob {
    display: none;
  }

  .socialIcon img {
    width: 43px;
    height: 43px;
    cursor: pointer;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }

  .light {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background: radial-gradient(circle, rgba(0,0,0,0.3841911764705882) 0%, rgba(0,0,0,0.4962359943977591) 35%, rgba(0,0,0,0.5326505602240896) 100%), url('./assets/bg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon {
    padding-right: 21px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Poppins", sans-serif;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 10%;
  }

  .intro1 {
    color: white;
    text-align: center;
    font-size: 24px;
    text-shadow: 0 0 10px #8df8d5;
  }

  .intro2 {
    background: linear-gradient(to bottom, #8df8d5 22%, #634f2c 24%, #8df8d5 26%, #8df8d5 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Playfair Display', serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    font-size: 56px;
    width: 100%;
    text-align: center;
  }

  .btnsMain {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 4%;
    justify-content: space-evenly;
    width: 850px;
  }

  .eachBtns {
    text-align: center;
    font-size: 24px;
  }

  .eachBtns div {
    font-weight: 500;
    margin-bottom: 15px;
    color: rgb(167, 167, 167);
  }

  .btn1 {
    color: white;
    padding: 22px;
    border-radius: 100px;
    border: 1px #8b7245 solid;
    background-color: #111111b2;
    width: 340px;
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
  }

  .btn1:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 10px #8df8d5;
  }

  .btn2 {
    color: white;
    padding: 22px;
    border-radius: 100px;
    background-color: #111111b2;
    width: 340px;
    background-color: #8b7245;
    border: none;
    background: rgb(99, 79, 44);
    background: linear-gradient(180deg, rgba(99, 79, 44, 1) 0%, rgb(156, 143, 115) 100%);
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;

  }

  .btn2:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 10px #8df8d5;
  }

  .logoPic {
    width: 145px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    width: 100%;
    background-color: #0a0900;
    color: #52b396;
    text-align: center;
    padding-top: 22px;
    padding-bottom: 22px;
    text-shadow: 0 0 10px #fdd5968a;
    font-size: 22px;
  }

  .btn {
    display: inline-block;
    background: transparent;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    letter-spacing: 0.3em;
    color: rgba(223, 190, 106, 0.7);
    border-radius: 0;
    padding: 20px 50px 26px;
    transition: all 0.7s ease-out;
    background: linear-gradient(270deg, rgba(223, 190, 106, 0.8), rgba(146, 111, 52, 0.8), rgba(34, 34, 34, 0), rgba(34, 34, 34, 0));
    background-position: 1% 50%;
    background-size: 300% 300%;
    text-decoration: none;
    margin: 0.625rem;
    border: none;
    border: 1px solid rgba(223, 190, 106, 0.3);
    border-radius: 100px;
    box-shadow: 0 0 25px rgba(192, 169, 111, 0.37);
  }

  .btn:hover {
    color: #fff;
    border: 1px solid rgba(223, 190, 106, 0);
    background-position: 99% 50%;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  #noNFTs{
    font-size: 80px;
    color: #614d2c;
    font-weight: bolder;
  }
  .totalAmount {
    color: #8b7245;
    font-weight: bold;
    text-align: center;
    font-size: 32px;
  }

  .notification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    color: #b49560;
    font-weight: bold;
    width: 500px;
    text-align: center;
    font-size: 32px;
  }

  .commissionAmount div {
    padding-left: 10px;
    padding-right: 10px;
  }

  .commissionAmount {
    display: flex;
    justify-content: space-evenly;
  }

  .commissionAmountMobile {
    display: none;
  }

  .popup-content button:hover {
    background-color: #ceaa68;
  }

  .eachBoxHeaders {
    display: flex;
    justify-content: space-evenly;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 40px auto;
    border-radius: 60px;
  }

  th,
  td {
    padding: 18px;
    text-align: center;
    border-bottom: 1px solid #8b7245a6;
    font-size: 32px;
  }

  th {
    background-color: #be9b5a4d;
    color: white;
    border-radius: 15px;
    border-bottom: 1px solid #8b724500;
    width: 200px;
    font-size: 32px;
  }

  tr:nth-child(even) {
    background-color: #be9b5a00;
    border-radius: 60px;
    color: #e2bc76;

  }

  tr:nth-child(odd) {
    background-color: #8b724500;
    color: #e2bc76;
    border-radius: 60px;
  }

  #errorMessage {
    color: rgb(255, 171, 171);
    margin-top: 30px;
    font-size: 32px;
  }

  #loadingText {
    color: #d4bb8d;
    margin-top: 30px;
    font-size: 32px;
  }

  #success-message {
    color: #52b396;
    margin-top: 30px;
    font-size: 32px;
  }

  .popup {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .popup-content {
    width: 30%;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
  }

  .leaderboardMain {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 25px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 40px;
    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
    margin-top: 7%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    margin-top: 0;
    font-size: 34px;
    text-align: center;
    color: #d4bb8d;
  }

  h3 {
    color: #af9668;
    text-shadow: 0 0 10px #9c865d;
    font-size: 50px;
  }

  .pDivHeaders {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .pDivHeadersSub {
    display: flex;
    justify-content: space-evenly;
  }

  .refH {
    margin-right: 60px
  }

  .refH-2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .h4 {
    font-size: 34px;
    color: #8b7245;
    transition: 0.2s ease-in-out;
  }

  .rank:hover {
    color: #be9b5a;
    cursor: pointer;
  }

  .user {
    width: 60px;
    height: 60px;
    margin-top: -8px;
    margin-right: 20px;
  }

  .user2 {
    width: 55px;
    margin-top: -8px;
    margin-right: 20px;
  }


  .btnT {
    margin-top: -20px;
    margin-bottom: 10px;
  }

  .btnT button {
    background-color: #201c15;
    color: #af9668;
    border-radius: 100px;
    border: 1px;
    color: #ffffff;
    border-radius: 100px;
    border: 1px #8b7245 solid;
    background-color: #111111b2;
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(163, 133, 80, 0.637) 0px 7px 29px 0px;
    text-shadow: 0 0 45px #8df8d5;
    width: 90%;
    padding: 5px;
  }

  .btnT button:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 45px #8df8d5;
  }

  .btnT img {
    width: 22px;
    height: 22px;
    margin-right: 5px;
    margin-top: -5px;
    opacity: 0.6;
  }

  .accR {
    color: #8b7245;
    font-size: 32px;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 25px;
    cursor: pointer;
    font-size: 50px;
    z-index: 50;
    color: #a19c92;
    transition: 0.2s ease-in-out;
  }

  .close:hover {
    color: #d6d0c4;
  }

  .popup-content input[type="text"] {
    width: 100%;
    padding: 15px;
    border: 1px solid #8b7245;
    border-radius: 12px;
    box-sizing: border-box;
    background-color: #0a0900bb;
    color: #a58852;
    font-size: 32px;
    margin-top: 30px;
  }

  .popup-content2 {
    width: 95%;
    height: 95vh;
    text-align: center;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

    background: rgba(255, 255, 255, 0.2);
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;

    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
  }

  /* NFT section styles */

  .nft-sectionMain {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 20px 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
  }

  .nft-section {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 30px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
  }

  .eachCollection {
    background-color: #35290ed0;
    border-radius: 40px;
    margin: 40px;
    padding: 30px;
  }

  .eachCollection div {
    color: #b49560;
    text-align: center;
    font-size: 32px;
  }

  .nft-card {
    padding: 20px;
    margin: 10px;
    border-radius: 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    width: 20%;
    border: 1px solid #8b724579;
    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(75, 53, 16, 0.277) 0%, rgba(43, 31, 12, 0.384) 61%, rgba(102, 81, 43, 0.851) 100%);
  }

  .nft {
    width: 90%;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .copy {
    width: 32px;
    height: 32px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 7px;
    cursor: pointer;
  }

  .nft-card p {
    font-size: 14px;
    margin-bottom: 10px;
    color: #fdf1db;
    text-shadow: 0 0 10px #ffffff3a;
    text-align: left;
  }

  #id {
    text-align: center;
    color: #ffe5b5;
    font-weight: bold;
    font-size: 30px;
  }

  .accIn {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .account {
    color: #cfcfcf;
    text-align: left;
    font-size: 32px;
  }

  .popup-content button {
    background-color: #a58852;
    color: white;
    padding: 15px 25px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 32px;
    transition: 0.2s ease-in-out;
  }

  .intro2-mob {
    display: none;
  }

  .socialIcon img {
    width: 60px;
    height: 60px;
    cursor: pointer;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }

  .light {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background: radial-gradient(circle, rgba(0,0,0,0.3841911764705882) 0%, rgba(0,0,0,0.4962359943977591) 35%, rgba(0,0,0,0.5326505602240896) 100%), url('./assets/bg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon {
    padding-right: 28px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Poppins", sans-serif;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 10%;
  }

  .intro1 {
    color: white;
    text-align: center;
    font-size: 34px;
    text-shadow: 0 0 10px #8df8d5;
  }

  .intro2 {
    background: linear-gradient(to bottom, #8df8d5 22%, #634f2c 24%, #8df8d5 26%, #8df8d5 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Playfair Display', serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    font-size: 78px;
    width: 100%;
    text-align: center;
  }

  .btnsMain {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 4%;
    justify-content: space-evenly;
    width: 1180px;
  }

  .eachBtns {
    text-align: center;
    font-size: 32px;
  }

  .eachBtns div {
    font-weight: 500;
    margin-bottom: 22px;
    color: rgb(167, 167, 167);
  }

  .btn1 {
    color: white;
    padding: 26px;
    border-radius: 100px;
    border: 1px #8b7245 solid;
    background-color: #111111b2;
    width: 470px;
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
  }

  .btn1:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 20px #8df8d5;
  }

  .btn2 {
    color: white;
    padding: 26px;
    border-radius: 100px;
    background-color: #111111b2;
    width: 470px;
    background-color: #8b7245;
    border: none;
    background: rgb(99, 79, 44);
    background: linear-gradient(180deg, rgba(99, 79, 44, 1) 0%, rgb(156, 143, 115) 100%);
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;

  }

  .btn2:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 20px #8df8d5;
  }

  .logoPic {
    width: 200px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    width: 100%;
    background-color: #0a0900;
    color: #52b396;
    text-align: center;
    padding-top: 26px;
    padding-bottom: 26px;
    text-shadow: 0 0 10px #fdd5968a;
    font-size: 30px;
  }

  .btn {
    display: inline-block;
    background: transparent;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    font-size: 21px;
    letter-spacing: 0.3em;
    color: rgba(223, 190, 106, 0.7);
    border-radius: 0;
    padding: 30px 70px 35px;
    transition: all 0.7s ease-out;
    background: linear-gradient(270deg, rgba(223, 190, 106, 0.8), rgba(146, 111, 52, 0.8), rgba(34, 34, 34, 0), rgba(34, 34, 34, 0));
    background-position: 1% 50%;
    background-size: 300% 300%;
    text-decoration: none;
    margin: 0.625rem;
    border: none;
    border: 1px solid rgba(223, 190, 106, 0.3);
    border-radius: 100px;
    box-shadow: 0 0 25px rgba(192, 169, 111, 0.37);
  }

  .btn:hover {
    color: #fff;
    border: 1px solid rgba(223, 190, 106, 0);
    background-position: 99% 50%;
  }
}

@media screen and (min-width: 3840px) {
  #noNFTs{
    font-size: 110px;
    color: #614d2c;
    font-weight: bolder;
  }
  .totalAmount {
    color: #8b7245;
    font-weight: bold;
    text-align: center;
    font-size: 45px;
  }

  
  .notification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 60px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    color: #b49560;
    font-weight: bold;
    width: 600px;
    text-align: center;
    font-size: 42px;
  }


  .commissionAmount div {
    padding-left: 10px;
    padding-right: 10px;
  }

  .commissionAmount {
    display: flex;
    justify-content: space-evenly;
  }

  .commissionAmountMobile {
    display: none;
  }

  .popup-content button:hover {
    background-color: #ceaa68;
  }

  .eachBoxHeaders {
    display: flex;
    justify-content: space-evenly;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 40px auto;
    border-radius: 80px;
  }

  th,
  td {
    padding: 25px;
    text-align: center;
    border-bottom: 1px solid #8b7245a6;
    font-size: 45px;
  }

  th {
    background-color: #be9b5a4d;
    color: white;
    border-radius: 20px;
    border-bottom: 1px solid #8b724500;
    width: 200px;
    font-size: 45px;
  }

  tr:nth-child(even) {
    background-color: #be9b5a00;
    border-radius: 60px;
    color: #e2bc76;

  }

  tr:nth-child(odd) {
    background-color: #8b724500;
    color: #e2bc76;
    border-radius: 60px;
  }

  #errorMessage {
    color: rgb(255, 171, 171);
    margin-top: 45px;
    font-size: 42px;
  }

  #loadingText {
    color: #d4bb8d;
    margin-top: 45px;
    font-size: 42px;
  }

  #success-message {
    color: #52b396;
    margin-top: 45px;
    font-size: 42px;
  }

  .popup {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .popup-content {
    width: 30%;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 30px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 80px;

    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
  }

  .leaderboardMain {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 55px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 60px;
    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
    margin-top: 7%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    margin-top: 0;
    font-size: 55px;
    text-align: center;
    color: #d4bb8d;
  }

  h3 {
    color: #af9668;
    text-shadow: 0 0 10px #9c865d;
    font-size: 75px;
  }

  .pDivHeaders {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .pDivHeadersSub {
    display: flex;
    justify-content: space-evenly;
  }

  .refH {
    margin-right: 85px
  }

  .refH-2 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .h4 {
    font-size: 53px;
    color: #8b7245;
    transition: 0.2s ease-in-out;
  }

  .rank:hover {
    color: #be9b5a;
    cursor: pointer;
  }

  .user {
    width: 90px;
    height: 90px;
    margin-top: -8px;
    margin-right: 40px;
  }

  .user2 {
    width: 80px;
    margin-top: -8px;
    margin-right: 20px;
  }


  .btnT {
    margin-top: -20px;
    margin-bottom: 10px;
  }

  .btnT button {
    background-color: #201c15;
    color: #af9668;
    border-radius: 100px;
    border: 1px;
    color: #ffffff;
    border-radius: 100px;
    border: 1px #8b7245 solid;
    background-color: #111111b2;
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(163, 133, 80, 0.637) 0px 7px 29px 0px;
    text-shadow: 0 0 45px #8df8d5;
    width: 90%;
    padding: 5px;
  }

  .btnT button:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 45px #8df8d5;
  }

  .btnT img {
    width: 22px;
    height: 22px;
    margin-right: 5px;
    margin-top: -5px;
    opacity: 0.6;
  }

  .accR {
    color: #8b7245;
    font-size: 45px;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 45px;
    cursor: pointer;
    font-size: 75px;
    z-index: 50;
    color: #a19c92;
    transition: 0.2s ease-in-out;
  }

  .close:hover {
    color: #d6d0c4;
  }

  .popup-content input[type="text"] {
    width: 100%;
    padding: 30px;
    border: 1px solid #8b7245;
    border-radius: 15px;
    box-sizing: border-box;
    background-color: #0a0900bb;
    color: #a58852;
    font-size: 45px;
    margin-top: 40px;
  }

  .popup-content2 {
    width: 95%;
    height: 95vh;
    text-align: center;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

    background: rgba(255, 255, 255, 0.2);
    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 80px;

    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(149, 116, 61, 0.13126969537815125) 0%, rgba(102, 76, 33, 0.4225862219887955) 61%, rgba(83, 76, 38, 0.40858061974789917) 100%);
  }

  /* NFT section styles */

  .nft-sectionMain {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 90px 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
  }

  .nft-section {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 30px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
  }

  .eachCollection {
    background-color: #35290ed0;
    border-radius: 40px;
    margin: 40px;
    padding: 30px;
  }

  .eachCollection div {
    color: #b49560;
    text-align: center;
    font-size: 48px;
  }

  .nft-card {
    padding: 30px;
    margin: 20px;
    border-radius: 60px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    width: 20%;
    border: 1px solid #8b724579;
    background: rgb(149, 116, 61);
    background: radial-gradient(circle, rgba(75, 53, 16, 0.277) 0%, rgba(43, 31, 12, 0.384) 61%, rgba(102, 81, 43, 0.851) 100%);
  }

  .nft {
    width: 90%;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .copy {
    width: 45px;
    height: 45px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 7px;
    cursor: pointer;
  }

  .nft-card p {
    font-size: 14px;
    margin-bottom: 10px;
    color: #fdf1db;
    text-shadow: 0 0 10px #ffffff3a;
    text-align: left;
  }

  #id {
    text-align: center;
    color: #ffe5b5;
    font-weight: bold;
    font-size: 40px;
  }

  .accIn {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .account {
    color: #cfcfcf;
    text-align: left;
    font-size: 45px;
  }

  .popup-content button {
    background-color: #a58852;
    color: white;
    padding: 20px 30px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 45px;
    transition: 0.2s ease-in-out;
  }

  .intro2-mob {
    display: none;
  }

  .socialIcon img {
    width: 90px;
    height: 90px;
    cursor: pointer;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }

  .light {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
    background: radial-gradient(circle, rgba(0,0,0,0.3841911764705882) 0%, rgba(0,0,0,0.4962359943977591) 35%, rgba(0,0,0,0.5326505602240896) 100%), url('./assets/bg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon {
    padding-right: 50px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Poppins", sans-serif;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 55px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .introduction {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 10%;
  }

  .intro1 {
    color: white;
    text-align: center;
    font-size: 50px;
    text-shadow: 0 0 10px #8df8d5;
    margin-bottom: 10px;
  }

  .intro2 {
    background: linear-gradient(to bottom, #8df8d5 22%, #634f2c 24%, #8df8d5 26%, #8df8d5 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Playfair Display', serif;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    font-size: 110px;
    width: 100%;
    text-align: center;
  }

  .btnsMain {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 4%;
    justify-content: space-evenly;
    width: 1650px;
  }

  .eachBtns {
    text-align: center;
    font-size: 48px;
  }

  .eachBtns div {
    font-weight: 500;
    margin-bottom: 22px;
    color: rgb(167, 167, 167);
  }

  .btn1 {
    color: white;
    padding: 40px;
    border-radius: 100px;
    border: 1px #8b7245 solid;
    background-color: #111111b2;
    width: 650px;
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
  }

  .btn1:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 45px #8df8d5;
  }

  .btn2 {
    color: white;
    padding: 40px;
    border-radius: 100px;
    background-color: #111111b2;
    width: 650px;
    background-color: #8b7245;
    border: none;
    background: rgb(99, 79, 44);
    background: linear-gradient(180deg, rgba(99, 79, 44, 1) 0%, rgb(156, 143, 115) 100%);
    font-weight: 500;
    box-shadow: rgba(163, 133, 80, 0.164) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;

  }

  .btn2:hover {
    box-shadow: rgba(163, 133, 80, 0.384) 0px 7px 29px 0px;
    text-shadow: 0 0 45px #8df8d5;
  }

  .logoPic {
    width: 300px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    width: 100%;
    background-color: #0a0900;
    color: #52b396;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    text-shadow: 0 0 10px #fdd5968a;
    font-size: 48px;
  }

  .btn {
    display: inline-block;
    background: transparent;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    font-size: 32px;
    letter-spacing: 0.3em;
    color: rgba(223, 190, 106, 0.7);
    border-radius: 0;
    padding: 45px 90px 50px;
    transition: all 0.7s ease-out;
    background: linear-gradient(270deg, rgba(223, 190, 106, 0.8), rgba(146, 111, 52, 0.8), rgba(34, 34, 34, 0), rgba(34, 34, 34, 0));
    background-position: 1% 50%;
    background-size: 300% 300%;
    text-decoration: none;
    margin: 0.625rem;
    border: none;
    border: 1px solid rgba(223, 190, 106, 0.3);
    border-radius: 100px;
    box-shadow: 0 0 25px rgba(192, 169, 111, 0.37);
  }

  .btn:hover {
    color: #fff;
    border: 1px solid rgba(223, 190, 106, 0);
    background-position: 99% 50%;
  }
}